import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class LogService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/log';
    }

    getAllUserLogs(userId) {
        let url = `${this.#api}/${userId}/user`
        return apiService.get(url)
    }
    getApplicationLogs(userId, applicationId) {
        let url = `${this.#api}/${userId}/application/${applicationId}`
        return apiService.get(url)
    }
}
