<template>
    <h2 class="font-size-xl text-secondary">
        <i style="font-size: 2em;" class="d-block fa fa-box font-size-xl"></i>
        <span class="mt-4">
        {{message}}
        </span>
    </h2>
</template>

<script>
export default {
    props: ['message']   
}
</script>
