<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Application Offer</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Application Offers
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                         @click="createOffer()"
                         class="btn btn-primary"
                  >
                    Add Application Offer
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="search.offer_letter_number" outlined dense clearable
                                label="Search by offer letter number"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select :items="statuses" v-model="search.status" item-value="value" item-text="name" outlined
                            dense
                            clearable
                            label="Application Status"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-btn class="btn btn-primary text-white" depressed @click="searchApplicationOffers">Search</v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="row">
              <v-col cols="12">
                <table class="table">
                  <thead>
                  <tr>
                    <th>
                      Offer Letter Date
                    </th>
                    <th>
                      Offer Validate Date
                    </th>
                    <th>
                      Offer Letter Number
                    </th>
                    <th>
                      Status
                    </th>
                    <th class="text-center">
                      Actions
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="applicationOffers.length>0">
                    <tr v-for="(application, index) in applicationOffers" :key="index">
                      <td>
                        {{ application.formatted_offer_letter_date ?  application.formatted_offer_letter_date : 'N/A' }}
                      </td>

                      <td>
                        {{ application.formatted_offer_validate_date ? application.formatted_offer_validate_date : 'N/A' }}
                      </td>

                      <td>
                        {{ application.offer_letter_number ? application.offer_letter_number : 'N/A' }}
                      </td>
                      <td>
                        <span v-html="application.status_text"></span>
                      </td>
                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="openLetterAttachment(application.letter_attachment_path)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-eye</v-icon>
                                </span>
                                  <span class="navi-text">Letter</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="editApplicationOffer(application)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteApplicationOffer(application.id)">
                                <span class="navi-icon">
                                    <v-icon color="red darken-2">fas fa-trash</v-icon>
                                </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td class="text-center" colspan="50">No data found</td>
                    </tr>
                  </template>
                  </tbody>
                </table>
                <b-pagination
                    v-if="applicationOffers.length > 0"
                    class="pull-right mt-7"
                    @input="getAllApplicationOffers"
                    :disabled="loading"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>
              </v-col>
            </div>
          </div>

          <create-and-update ref="create-and-update" @refresh="getAllApplicationOffers"></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>

import ApplicationOfferService from "@/services/application/offer/ApplicationOfferService";
import CreateAndUpdate from "./CreateAndUpdate";

const applicationOffer = new ApplicationOfferService();
export default {
  name: "ApplicationOffer",
  props: ['applicationId'],
  components: {CreateAndUpdate},
  data() {
    return {
      loading: false,
      applicationOffers: [],
      total: null,
      perPage: null,
      page: null,
      statuses: [
        {name: 'Received', value: 'received'},
        {name: 'Accepted', value: 'accepted'},
        {name: 'Decline', value: 'decline'},
        {name: 'Expired', value: 'expired'}
      ],
      search: {
        application_id: '',
        offer_letter_number: '',
        status: '',
      }
    }
  },
  mounted() {
    this.search.application_id = this.applicationId;
    this.getAllApplicationOffers();
  },
  methods: {
    openLetterAttachment(file_path) {
      if(file_path) {
        window.open(file_path.real);
      }
    },
    createOffer() {
      this.$refs['create-and-update'].createApplicationOffer(this.applicationId);
    },
    editApplicationOffer(applicationOffer) {
      this.$refs['create-and-update'].editApplicationOffer(applicationOffer)
    },
    getAllApplicationOffers() {
      this.loading = true;
      applicationOffer
          .paginate(this.search, this.page)
          .then(response => {
            this.applicationOffers = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    searchApplicationOffers() {
      this.getAllApplicationOffers();
    },
    deleteApplicationOffer(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            applicationOffer
                .delete(id)
                .then((response) => {
                  this.getAllApplicationOffers()
                  this.$snotify.success("Application Offer Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  }
}
</script>
