<template>
  <v-dialog
      v-model="dialog"
      scrollable
      max-width="800"
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Add followup</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              Remarks *
              <ckeditor
                  :config="editorConfig"
                  v-model="noteFollowup.remarks"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.noteFollowup.remarks.$error">This information is required</span>
              <span class="text-danger" v-if="errors.remarks" >* {{ errors.remarks[0] }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
           large
            @click="closeDialog"
            text
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="loading"
            class="btn btn-primary font-weight-bolder font-size-sm"
            large
            @click="createOrUpdate()"
            dark
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import NoteFollowupService from "@/services/note/followup/NoteFollowupService";

const noteFollowup = new NoteFollowupService();
export default {
  name: "AddNoteFollowup",
  data() {
    return {
      errors: [],
      dialog: false,
      loading: false,
      noteId: '',
      noteFollowup: {
        note_id: '',
        remarks: '',
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList'],
        ]
      }
    }
  },
  validations: {
    noteFollowup: {
      remarks: { required },
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    addNoteFollowup(id) {
      this.openDialog();
      this.noteFollowup.note_id = id;
    },

    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        noteFollowup
            .create(this.noteFollowup)
            .then(response => {
              this.$snotify.success("Note followup added");
              this.resetForm();
              this.closeDialog();
              this.loading = false;
              this.$emit('refresh');
            })
            .catch((err) => {
              this.loading = false;
              this.errors = err.errors;
              this.$snotify.error("Oops something went wrong");
            });
      }
    },
    resetForm() {
      this.errors=[];
      this.$v.$reset();
      this.noteFollowup = {
        remarks: '',
      };
    },
  }
}
</script>
