<template>
  <div v-if="document_list && document_list.length>0">
    <v-card class="mt-4" v-for="(document, index) in document_list" :key="index"
            v-if="document.required_list">
      <v-card-title class="d-flex bg-gray justify-space-between">
        <div>
          {{ document.title }}
          <br>
          <small
              v-if="document.required_list && document.required_list.length>0 && document.required_list[0].is_submitted">
            Last document submitted on {{
              document.required_list[0].submitted_date
            }}
          </small>
        </div>

      </v-card-title>
      <v-card-text v-for="(reqList,i ) of document.required_list" :key="i">
        <div class="d-flex border align-items-center">
          <div class="required-text-wrapper" style="height: 117px;">
            <p :class="reqList.is_required?'required-text ':'optional-text'"
               class="p-3">
              {{ reqList.is_required ? 'Required' : 'Optional' }}
            </p>
          </div>
          <div class="d-flex" style="flex: 1; align-items: center; justify-content: space-between;">
            <div style="flex: 1;">
              <div class="green--text" v-if="reqList.is_submitted">
                <i class="fa fa-check-circle"></i> Completed
              </div>
              <div class="headline" style="font-size: 1.2rem !important;">
                {{ reqList.title }}
              </div>
              <div class="body" v-html="reqList.reference_description">

              </div>
            </div>

            <div class="body mr-5">
              <a :href="reqList.reference_document['real']"
                 target="_blank"
                 v-if="reqList.reference_document && reqList.reference_document.real">
                <i class="fa fa-file-pdf"></i>
                {{ reqList.reference_file_name }}
              </a>
              <v-btn small class="p-2" outlined color="blue"
                     @click="manageRequiredDocument(reqList, 'open')">
                <i class="fas fa-upload"> </i>

              </v-btn>
              <v-btn
                  @click="openDocument(reqList.user_submitted_file.real)"
                  small class="p-2" outlined color="blue"
                  v-if="reqList.user_submitted_file && reqList.user_submitted_file.real">
                <i class="fas fa-file-pdf"> </i>
              </v-btn>

              <v-btn
                  @click="deleteDocument(reqList.user_id, reqList.user_document_id)"
                  small class="p-2" outlined color="blue"
                  v-if="reqList.user_document_id && reqList.user_submitted_file">
                <i class="fas fa-trash"> </i>
              </v-btn>
              <!--                                                                <a class="btn btn-primary"  :href="" target="_blank">View File</a>-->
            </div>
          </div>
        </div>
<!--        <div class="d-flex border align-items-center">-->
<!--          <div class="p-3 required-text-wrapper">-->
<!--            <p class="p-2"-->
<!--               :class="reqList.is_required?'required-text ':'optional-text'">-->
<!--              {{ reqList.is_required ? 'required' : 'Optional' }}-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="p-3">-->
<!--            <div class="green&#45;&#45;text" v-if="reqList.is_submitted">-->
<!--              <i class="fa fa-check-circle"></i> Completed-->
<!--            </div>-->
<!--            <div class="headline">-->
<!--              {{ reqList.title }}-->
<!--            </div>-->
<!--            <div class="body" v-html="reqList.reference_description">-->

<!--            </div>-->
<!--            <div class="body">-->
<!--              <div class="row">-->
<!--                <div class="col-12">-->
<!--                  <a v-if="reqList.reference_document && reqList.reference_document.real"-->
<!--                     :href="reqList.reference_document['real']"-->
<!--                     target="_blank">-->
<!--                    <i class="fa fa-file-pdf"></i>-->
<!--                    {{ reqList.reference_file_name }}-->
<!--                  </a>-->
<!--                  <a href="#" class="btn btn-primary" style="margin-left: 100px"-->
<!--                     @click="manageRequiredDocument(reqList, 'open')">-->
<!--                    <i class="fas fa-upload"></i>-->
<!--                  </a>-->
<!--                  <a class="btn btn-secondary" style="margin-left: 20px"-->
<!--                     v-if="reqList.user_submitted_file && reqList.user_submitted_file.real"-->
<!--                     :href="reqList.user_submitted_file.real" target="_blank">-->
<!--                    <i class="fas fa-external-link-square-alt"></i>-->
<!--                  </a>-->

<!--                </div>-->
<!--              </div>-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </v-card-text>
    </v-card>
    <v-dialog persistent max-width="600" v-model="require_document_dialog">
      <v-card>
        <v-card-title>
          {{ document_required.document_type == 'file' ? 'Add Required Document' : 'Write some word' }}
        </v-card-title>
        <v-card-text>
          <v-row v-if="document_required.document_type == 'file'">
            <v-col cols="12" v-if="document_required.user_submitted_file && document_required.user_submitted_file.real">
              <a :href="document_required.user_submitted_file.real" target="_blank">View File</a>
            </v-col>
            <v-col cols="12">
              <v-file-input outlined dense v-model="document_required.file"
                            :error="$v.document_required.file.$error"
                            label="Upload File"></v-file-input>
              <span style="color: red;" class="text-danger" v-if="$v.document_required.file.$error">
                                File is required
                            </span>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <v-textarea outlined dense v-model="document_required.description" label=""
                          :error="$v.document_required.description.$error"
              ></v-textarea>
              <span style="color: red;" class="text-danger"
                    v-if="$v.document_required.description.$error">
                                Please write something
                            </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="mr-2" text dark medium depressed @click="manageRequiredDocument({}, 'close')">Close
          </v-btn>
          <v-btn :loading="loading" class="btn btn-primary text-white" depressed @click="createOrUpdate">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import UserDocumentService from "@/services/user/document/UserDocumentService";

const documentService = new UserDocumentService();
export default {
  name: "Document",
  props: ['document_list'],
  validations() {
    return {
      document_required: this.document_required_rule,
    }
  },
  computed: {
    document_required_rule() {
      let rule = {}
      if (this.document_required.document_type == 'file')
        rule.file = {required}
      else
        rule.description = {required}
      return rule;
    },
  },
  data() {
    return {
      loading: false,
      require_document_dialog: false,
      document_required: {
        description: null,
        file: null
      },
    }
  }, methods: {
    createOrUpdate() {
      this.$v.document_required.$touch();
      if (this.$v.document_required.$error) {
        setTimeout(() => {
          this.$v.document_required.$reset();
        }, 3000)
      } else {
        this.loading = true
        let fd = this.generateFd();
        documentService.createOrUpdate(fd).then(response => {
          this.loading = false
          this.$snotify.success("Required list has saved successfully")
          this.manageRequiredDocument({}, "close")
        }).catch(error => {
          this.loading = false
          this.$snotify.error("Something went wrong please try again later")
        })
      }
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.document_required) {
        if (
            key == "file" &&
            this.document_required["file"] &&
            this.document_required["file"] != null
        ) {
          fd.append("file", this.document_required['file']);
        } else {
          fd.append(key, this.document_required[key] ? this.document_required[key] == true ? 1 : this.document_required[key] : "");
        }
      }
      return fd
    },
    manageRequiredDocument(requireList = {}, type = 'open') {
      if (type == 'open') {
        if (requireList) {
          this.document_required = requireList
        }
        this.require_document_dialog = true
      } else {
        this.resetForm();
        this.require_document_dialog = false
      }
    },
    resetForm() {
      this.document_required = {
        description: null,
        file: null
      }
      this.$v.document_required.$reset();
      this.$emit('update-require-document')
    },
    openDocument(file) {
      window.open(file);
    },
    deleteDocument(userId, documentId) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            documentService
                .delete(userId, documentId)
                .then(() => {
                  this.$emit('update-require-document');
                  this.$snotify.success("Document Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }
  }
}
</script>

<style scoped>
.badge-secondary {
  background: #efefef;
}

.required-text {
  color: #fff;
  background: rgb(3, 123, 252);
}

.optional-text {
  color: #fff;
  background: rgb(0, 153, 0);
}

.required-text-wrapper {
  -webkit-transform: rotate(-90deg);
}

.bg-slate-200 {
  background-color: rgb(226 232 240);
}
.v-application .headline {
  font-family: var(--font) !important;
}
.v-tabs--vertical .v-tab {
  justify-content: left;
}
</style>