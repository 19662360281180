<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Application Offer</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-menu
                  ref="menuEndDate"
                  v-model="menuLetterDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="applicationOffer.offer_letter_date"
                      readonly
                      outlined
                      clearable
                      dense
                      v-bind="attrs"
                      v-on="on"
                  >
                    <template v-slot:label>
                      Offer Letter Date <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="applicationOffer.offer_letter_date"
                    outlined
                    dense
                    no-title
                    :min="currentDate"
                    @input="menuLetterDate = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="6">
              <v-menu
                  ref="menuValidateDate"
                  v-model="menuValidateDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="applicationOffer.offer_validate_date"
                      readonly
                      outlined
                      clearable
                      dense
                      v-bind="attrs"
                      v-on="on"
                  >
                    <template v-slot:label>
                      Offer Validate Date <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.applicationOffer.offer_letter_date.$error">This Field is required</span>
                </template>
                <v-date-picker
                    v-model="applicationOffer.offer_validate_date"
                    outlined
                    dense
                    no-title
                    :min="currentDate"
                    @input="menuValidateDate = false"
                >
                </v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.applicationOffer.offer_validate_date.$error">This Field is required.</span>
            </v-col>

            <v-col cols="12">
              <v-text-field
                  v-model="applicationOffer.offer_letter_number"
                  :error="$v.applicationOffer.offer_letter_number.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                Offer Letter Number <span class="text-danger">*</span>
              </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.applicationOffer.offer_letter_number.$error">This Field is required.</span>
            </v-col>

            <v-col cols="12">
              <v-file-input
                  label="Letter Attachment"
                  prepend-inner-icon="fas fa-file"
                  prepend-icon=""
                  v-model="applicationOffer.file"
                  :error="$v.applicationOffer.file.$error"
                  outlined
                  dense
              >
              </v-file-input>
              <span class="text-danger" v-if="$v.applicationOffer.file.$error">This Field is required.</span>
            </v-col>
            <v-col cols="12" v-if="img">
              <a :href="img" target="_blank"><i class="fas fa-file"></i> Letter Attachment</a>
            </v-col>

            <v-col cols="12">
              <v-select
                  label="Status"
                  v-model="applicationOffer.status"
                  :error="$v.applicationOffer.status.$error"
                  :items="status"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Status <span class="text-danger">*</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark medium
            @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="loading"
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import ApplicationOfferService from "@/services/application/offer/ApplicationOfferService";

const applicationOffer = new ApplicationOfferService();

export default {
  name: "CreateAndUpdate",
  validations: {
    applicationOffer: {
      offer_letter_date: { required },
      offer_validate_date: { required },
      offer_letter_number: { required },
      file: { required:requiredIf(function () {
        return !this.edit;
        }) },
      status: { required }
    }
  },
  data() {
    return {
      title: '',
      dialog: false,
      loading: false,
      menuLetterDate: false,
      menuValidateDate: false,
      edit: false,
      img: null,
      errors: [],
      applicationOffer: {
        application_id:'',
        offer_letter_date:'',
        offer_validate_date: '',
        offer_letter_number:'',
        file: null,
        status: 'accepted',
      },
      status: [
        {name: 'Received', value: 'received'},
        {name: 'Accepted', value: 'accepted'},
        {name: 'Rejected', value: 'rejected'},
        {name: 'Expired', value: 'expired'}
      ],
      currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }
  },
  methods: {
    closeDialog() {
      this.dialog=false;
    },
    createApplicationOffer(applicationId) {
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
      this.applicationOffer.application_id = applicationId;
    },
    editApplicationOffer(applicationOffer) {
      this.title = 'Update';
      this.dialog = true;
      this.edit = true;
      this.resetForm();
      this.applicationOffer = applicationOffer;

      if(this.applicationOffer.letter_attachment_path) {
        this.img = this.applicationOffer.letter_attachment_path.real;
      }
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.applicationOffer) {
        if (key === "file" && this.applicationOffer[key] != null && this.applicationOffer[key] != undefined) {
          formData.append('file', this.applicationOffer[key]);
        } else {
          if(this.applicationOffer[key]){
            formData.append(key,this.applicationOffer[key]);
          }
        }
      }
      return formData;
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      this.loading = true;
      let form = this.convertToFormData();
      applicationOffer
          .create(form)
          .then(response => {
            this.$snotify.success("Application Offer created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update() {
      this.loading = true;
      let form = this.convertToFormData();
      applicationOffer
          .update(this.applicationOffer.id, form)
          .then(response => {
            this.$snotify.success("Application Offer updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.dialog = true;
      this.errors = [];
      this.img = null;
      this.$v.$reset();
      this.applicationOffer = {
        application_id:'',
        offer_letter_date:'',
        offer_validate_date: '',
        offer_letter_number:'',
        file: null,
        status: 'accepted',
      }
    },
  }
}
</script>