<template>
  <v-app class="m-5">
    <v-card-title>
      Application detail
    </v-card-title>
    <v-row>
      <!--        info here-->
      <application-detail-info :application="application"
                               :user_setting="user_setting"
                               :test_scores="test_scores"
                               :submitted_application="submitted_application"
                               @delete-application="deleteApplication"
                               @submit-application="submitApplication"
      ></application-detail-info>
      <!--          info ends here-->

      <v-col cols="12" md="9">
        <div class="application-main-wrapper p-3">
          <v-tabs vertical v-model="tabNumber">
            <v-tab>
              <v-icon left>
                mdi-chart-bar
              </v-icon>
              Progress
            </v-tab>

            <v-tab>
              <v-icon left>
                mdi-account
              </v-icon>
              Student personal info
            </v-tab>

            <v-tab>
              <v-icon left>
                mdi-file
              </v-icon>
              Documents <span class="">({{total_submitted}}/{{total_document}})</span>
            </v-tab>

            <v-tab>
              <v-icon left>
                mdi-file
              </v-icon>
              Offers
            </v-tab>

            <v-tab>
              <v-icon left>
                mdi-chat
              </v-icon>
              Discussions <span class=""></span>
            </v-tab>

            <!--                  <v-tab>-->
            <!--                    <v-icon left>-->
            <!--                      mdi-school-->
            <!--                    </v-icon>-->
            <!--                    Educational History <span class=""></span>-->
            <!--                  </v-tab>-->

            <v-tab>
              <v-icon left>
                mdi-school
              </v-icon>
              English proficiency test score <span class=""></span>
            </v-tab>

            <!--                  <v-tab>-->
            <!--                    <v-icon left>-->
            <!--                      mdi-briefcase-->
            <!--                    </v-icon>-->
            <!--                    Employments <span class=""></span>-->
            <!--                  </v-tab>-->

            <v-tab>
              <v-icon left>
                fas fa-address-book
              </v-icon>
              Address <span class=""></span>
            </v-tab>

            <v-tab>
              <v-icon left>
                fas fa-note-sticky
              </v-icon>
              Note <span class=""></span>
            </v-tab>

            <v-tab>
              <v-icon left>
                fas fa-dollar
              </v-icon>
              Payment list <span class=""></span>
            </v-tab>

            <!--                  <v-tab>-->
            <!--                    <v-icon left>-->
            <!--                      mdi-information-->
            <!--                    </v-icon>-->
            <!--                    Information Log-->
            <!--                  </v-tab>-->

            <v-tab-item>
              <application-history :histories="histories" :item="application" @update-list="getApplicationDetail"></application-history>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="m-3">
                      <v-row>
                        <v-col cols="12">
                          <h4><b>Personal information</b></h4>
                        </v-col>
                        <v-col cols="12" md="3">
                                <span ><b>Full Name: </b>
                                  <router-link
                                      :to="{ name:'student-profile', params:{'user_id':application.applicant_id} }">
                                    {{ application.applicant }}
                                  </router-link></span>
                        </v-col>

                        <!--                              <v-col cols="12" md="3">-->
                        <!--                                <p ><b>Date of Birth: </b> {{ application.applicant_date_of_birth }}</p>-->
                        <!--                              </v-col>-->

                        <v-col cols="12" md="3">
                          <p ><b>Gender: </b> {{ application.applicant_gender }}</p>
                        </v-col>

                        <v-col cols="12" md="3">
                          <p ><b>Marital Status: </b> {{ application.applicant_passport_is_married ? 'Married' : 'Single' }}</p>
                        </v-col>

                        <v-col cols="12" md="3">
                          <p ><b>First Language: </b> <v-chip class="mx-1" small v-if="application.applicant_first_language" v-for="(language, index) in application.applicant_first_language" :key="index">{{language}}</v-chip></p>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="m-3">
                      <v-row>
                        <v-col cols="12">
                          <h4><b>Citizenship and Residency Information</b></h4>
                        </v-col>

                        <v-col cols="12" md="3">
                          <p ><b>Passport Number: </b> {{ application.applicant_passport_number ? application.applicant_passport_number : 'N/A' }}</p>
                        </v-col>

                        <v-col cols="12" md="3">
                          <p ><b>Passport Expiry Date: </b> {{ application.applicant_passport_expiry_date ? application.applicant_passport_expiry_date : 'N/A' }}</p>
                        </v-col>
                      </v-row>

                    </v-col>
                    <v-col cols="12" class="m-3">
                      <v-row>
                        <v-col cols="12">
                          <h4><b>Address</b></h4>
                        </v-col>

                        <v-col cols="12" md="3">
                          <p ><b>Permanent Address: </b> {{ application.applicant_permanent_address ? application.applicant_permanent_address : 'N/A' }}</p>
                        </v-col>

                        <!--                              <v-col cols="12" md="3">-->
                        <!--                                <p ><b>Temporary Address: </b> {{ application.applicant_temporary_address ? application.applicant_temporary_address : 'N/A' }}</p>-->
                        <!--                              </v-col>-->
                      </v-row>

                    </v-col>
                    <v-col cols="12" class="m-3">
                      <v-row>
                        <v-col cols="12">
                          <h4><b>Contact Information</b></h4>
                        </v-col>

                        <v-col cols="12" md="3">
                          <p ><b>Phone: </b> {{ application.applicant_mobile || application.applicant_phone }}</p>
                        </v-col>

                        <v-col cols="12" md="3">
                          <p ><b>Email: </b> {{ application.applicant_email ? application.applicant_email : 'N/A' }}</p>
                        </v-col>
                      </v-row>

                    </v-col>
                    <!--                          <v-col cols="12" class="m-3">-->
                    <!--                            <v-row>-->
                    <!--                              <v-col cols="12">-->
                    <!--                                <h4><b>Emergency Contact </b></h4>-->
                    <!--                              </v-col>-->

                    <!--                              <v-col cols="12" md="3">-->
                    <!--                                <p ><b>Name: </b> {{ application.applicant_emergency_contact_name ? application.applicant_emergency_contact_name : 'N/A' }}</p>-->
                    <!--                              </v-col>-->

                    <!--                              <v-col cols="12" md="3">-->
                    <!--                                <p ><b>Relationship: </b>-->
                    <!--                                  <span v-if="application.applicant_relationship" class="badge badge-primary">{{ application.applicant_relationship }}</span>-->
                    <!--                                  <span v-else>N/A</span>-->
                    <!--                                </p>-->
                    <!--                              </v-col>-->

                    <!--                              <v-col cols="12" md="3">-->
                    <!--                                <p ><b>Email: </b> {{ application.applicant_emergency_contact_email ? application.applicant_emergency_contact_email : 'N/A' }}</p>-->
                    <!--                              </v-col>-->

                    <!--                              <v-col cols="12" md="3">-->
                    <!--                                <p ><b>Phone: </b> {{ application.applicant_emergency_contact_phone ? application.applicant_emergency_contact_phone : 'N/A' }}</p>-->
                    <!--                              </v-col>-->

                    <!--                              <v-col cols="12" md="3">-->
                    <!--                                <p ><b>Spoken Language: </b> <v-chip class="mx-1" small v-if="application.applicant_emergency_lanaguage" v-for="(language, index) in application.applicant_emergency_lanaguage" :key="index">{{language}}</v-chip></p>-->
                    <!--                              </v-col>-->
                    <!--                            </v-row>-->

                    <!--                          </v-col>-->
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <div class="d-flex">
                <div class="application-main-wrapper p-3">
                  <div class="action-buttons">
                    <v-btn
                        small
                        color="primary lighten-2"
                        dark
                    >
                      Total: {{ total_document }}
                    </v-btn>

                    <v-btn
                        class="mx-3"
                        small
                        color="purple lighten-2"
                        dark
                    >
                      Still to submit: {{ total_document - total_submitted }}
                    </v-btn>


                    <v-btn
                        small
                        color="green lighten-2"
                        dark
                    >
                      Submitted: {{ total_submitted }}
                    </v-btn>
                  </div>
                </div>
              </div>
              <require-document :document_list="document_list" @update-require-document="getRequirementList"></require-document>
            </v-tab-item>

            <v-tab-item>
              <application-offer v-if="application.id" :application-id="application.id"></application-offer>
            </v-tab-item>

            <v-tab-item>
              <application-discussion v-if="application.id" :user_id="application.applicant_id" :application-id="application.id" :agent_id="application.creator_by"></application-discussion>
            </v-tab-item>

            <!--                  <v-tab-item>-->
            <!--                    <academic-history :user_id="application.applicant_id"></academic-history>-->
            <!--                  </v-tab-item>-->

            <v-tab-item>
              <user-test-score v-if="application.applicant_id" :user_id="application.applicant_id"></user-test-score>
            </v-tab-item>

            <!--                  <v-tab-item>-->
            <!--                    <employment-history :user_id="application.applicant_id"></employment-history>-->
            <!--                  </v-tab-item>-->

            <v-tab-item>
              <user-address v-if="application.applicant_id" :user_id="application.applicant_id"></user-address>
            </v-tab-item>

            <v-tab-item>
              <notes v-if="application.application_created_by && application.id" :user-id="application.application_created_by" :application_id="application.id"></notes>
            </v-tab-item>

            <v-tab-item>
              <payment-list v-if="application.application_created_by && application.id" :agent_id="application.application_created_by" :application_id="application.id"></payment-list>
            </v-tab-item>

            <!--                  <v-tab-item>-->
            <!--                    <information-log :user_id="application.applicant_id" :application_id="application.id"></information-log>-->
            <!--                  </v-tab-item>-->

          </v-tabs>
        </div>
      </v-col>
    </v-row>

  </v-app>
</template>

<script>
import ApplicationService from "@/services/application/ApplicationService";
import ApplicationHistoryService from "@/services/application/history/ApplicationHistoryService";
import UserDocumentService from "@/services/user/document/UserDocumentService";
import UserSettingService from "@/services/user/setting/UserSettingService";
import UserTestScoreService from "@/services/user/test-score/UserTestScoreService";

import ApplicationHistory from "@/view/pages/view/user/student/profile/components/application/ApplicationHistory";
import ApplicationDetailInfo from "./detail/Info"
import RequireDocument from "./detail/RequireDocument"
import ApplicationDiscussion from "@/view/pages/view/user/student/profile/components/application-discussion/ApplicationDiscussion";
import AcademicHistory from "@/view/pages/view/user/student/profile/components/AcademicHistory";
import EmploymentHistory from "@/view/pages/view/user/student/profile/components/employment-history/EmploymentHistory";
import UserAddress from "@/view/pages/view/user/student/profile/components/UserAddress";
import PaymentList from "@/view/pages/view/user/student/profile/components/payment/PaymentList";
import InformationLog from "@/view/pages/view/user/general/information-log/InformationLog";
import UserTestScore from "@/view/pages/view/user/student/profile/components/UserTestScore";
import ApplicationOffer from "@/view/pages/view/application/detail/offer/ApplicationOffer";
import Notes from "@/view/pages/view/application/detail/notes/Notes";

const applicationService = new ApplicationService();
const applicationHistoryService = new ApplicationHistoryService();
const documentService = new UserDocumentService();
const userSettingService = new UserSettingService();
const userTestScoreService = new UserTestScoreService();

export default {
  props: ['user'],
  components: {
    Notes,
    ApplicationOffer,
    UserTestScore,
    InformationLog,
    PaymentList,
    UserAddress,
    EmploymentHistory,
    AcademicHistory,
    ApplicationDiscussion,
    ApplicationDetailInfo,
    RequireDocument,
    ApplicationHistory
  },
  data() {
    return {
      applicationId: null,
      application: {},
      user_setting: {},
      histories: [],
      document_list: [],
      total_document: 0,
      total_submitted: 0,
      test_scores: [],
      tabNumber: 0,
      submitted_application: false,
    }
  },
  computed: {
    application_id() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.applicationId = this.$route.params.application_id;
    this.getApplicationDetail();
  },
  methods: {
    getSubmittedApplication(userId) {
      applicationService
          .getSubmittedApplication(userId)
          .then((response) => {
            this.submitted_application = response.data.submitted_application;
          })
    },
    getUserSettingDetail() {
      userSettingService.index(this.application.applicant_id).then(response => {
        this.user_setting = response.data.user_setting
      })
    }, getUserTestScore() {
      userTestScoreService.all(this.application.applicant_id).then(response => {
        this.test_scores = response.data.test_scores
      })
    },
    getRequirementList() {
      applicationService.getRequiredDocumentList(this.application_id).then(response => {
        this.document_list = response.data.document_list;
        this.total_document = response.data.total_document;
        this.total_submitted = response.data.total_submitted;
      }).catch(error => {
        this.document_list = []
      })
    },
    getApplicationDetail() {
      applicationService
          .show(this.application_id)
          .then((response) => {
            this.application = response.data.application;
            this.getApplicationHistory();
            this.getRequirementList();
            this.getUserSettingDetail();
            this.getUserTestScore();
            this.getSubmittedApplication(this.application.applicant_id);
            this.markAsViewed(this.application_id);
            this.$store.dispatch('GET_APPLICATION_COUNT');
            if(this.$route.query.tabName) {
              this.tabNumber = parseInt(this.$route.query.tabName);
            }
          }).catch(error => {
        this.application = {}
      })
    },
    getApplicationHistory() {
      applicationService.getHistories(this.application.id).then(response => {
        if (response.data.status != "ERROR")
          this.histories = response.data.data
      }).catch(error => {
        this.histories = [];
      })
    },

    resetForm() {
      this.document_required = {
        description: null,
        file: null
      }
      this.$v.document_required.$reset();
      this.getRequirementList()
    },
    deleteApplication(id) {
      this.$confirm({
        message: `Are you sure you want remove application?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            applicationService.delete(id).then(response => {
              this.$snotify.success("Application deleted successfully")
              this.$store.dispatch('GET_APPLICATION_COUNT')
              this.$tabs.close().then(response => {
                this.$tabs.open({ name: 'applications',  params: { type:'academic', status: 'draft'} })
              })
            }).catch(error => {
              this.$snotify.error("Something went wrong please try again later")
            })
          }
        }
      });
    },
    submitApplication(application) {
      this.$confirm({
        message: `Are you sure you want submit application?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            applicationService.update(application.id, application).then(response => {
              this.displayMessage('success')
              this.$store.dispatch('GET_APPLICATION_COUNT')
            }).catch(error => {
              this.displayMessage('error')
            })
          }
        }
      });
    },
    markAsViewed(applicationId){
      applicationService
          .markAsViewed(applicationId)
          .then((res) => {
          })
          .catch((err) => {
          })
    }
  },
}
</script>



