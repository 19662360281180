import { render, staticRenderFns } from "./InformationLog.vue?vue&type=template&id=161ff1ae&scoped=true&"
import script from "./InformationLog.vue?vue&type=script&lang=js&"
export * from "./InformationLog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "161ff1ae",
  null
  
)

export default component.exports