<template>
    <v-app>
        <v-card raised>
            <v-card-text>
                <v-col v-if="logs.length==0"
                       class="text-center md:items-center md:justify-between py-12">
                    <img src="https://img.icons8.com/dotty/80/000000/empty-box.png" style="margin: 0 auto"/>
                    <p>No Information Log available</p>
                </v-col>

                <v-col cols="12" v-else>
                    <div class="contents__box contents__box--left w-100">
                        <div>
                            <ul>
                                <li class="mb-2 py-1" v-for="(item, index) in logs" :key="index">
                                    <code style="font-size: 14px;" class="ms-0">
                                        {{item.title}}
                                    </code>
                                    <span class="ml-2" style="font-size:14px;" v-if="item.admin_user && item.admin_user.length > 0">
                                        by {{item.admin_user[0].first_name}} {{item.admin_user[0].last_name}} on
                                    </span>
                                    <span class="ml-2" style="font-size:14px;" v-if="item.agent && item.agent.length > 0">
                                        by {{item.agent[0].first_name}} {{item.agent[0].last_name}} on
                                    </span>
                                    <code style="font-size:14px;">{{item.formatted_log_date}}</code>
                                    <a @click="showLogDetail(item)" style="font-size:14px;" class="fw-bold" target="_blank">Preview</a>.
                                </li>
                            </ul>
                        </div>
                      <b-pagination
                          v-if="logs.length > 0"
                          class="pull-right mt-7"
                          @input="searchLog"
                          v-model="page"
                          :total-rows="total"
                          :per-page="perPage"
                          first-number
                          :disabled="loading"
                          last-number
                      ></b-pagination>

                        <LogDetail ref="show-log-detail"></LogDetail>
                    </div>
                </v-col>

            </v-card-text>
        </v-card>
    </v-app>
</template>

<script>
import LogDetail from "./LogDetail";
import LogService from "@/services/log/LogService";

const log = new LogService()
export default {
    name: "InformationLog",
    props: ['user_id', 'application_id'],
    components: {LogDetail},
    data(){
        return{
            logs : [],
            total: null,
            perPage: null,
            page: null,
            loading: true,
            menu: false,
            search: {
                title: "",
                date_sent: ""
            }
        }
    },
    mounted() {
        if(this.application_id == undefined) {
            this.getAllUserLogs();
        }else {
            this.getApplicationLogs();
        }
    },
    methods: {
        searchLog() {
          if(this.application_id == undefined) {
            this.getAllUserLogs();
          }else {
            this.getApplicationLogs();
          }
        },
        getAllUserLogs() {
          log.getAllUserLogs(this.user_id).then(response => {
              this.logs = response.data.data;
              this.page = response.data.current_page;
              this.total = response.data.total;
              this.perPage = response.data.per_page;
          }).catch(() => {}).finally(() => {
              this.loading = false;
          })
        },
        getApplicationLogs() {
          log.getApplicationLogs(this.user_id, this.application_id).then(response => {
              this.logs = response.data.data;
              this.page = response.data.current_page;
              this.total = response.data.total;
              this.perPage = response.data.per_page;
          }).catch(() => {}).finally(() => {
              this.loading = false;
          })
        },
        showLogDetail(log){
            this.$refs['show-log-detail'].showLogDetail(log);
        }
    }
}
</script>

<style scoped>

</style>
